import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  // const authToken = localStorage.getItem("gToken");
  const userMsisdn = localStorage.getItem("userMsisdn");

  return userMsisdn ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
