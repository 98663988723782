import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  msisdn: localStorage?.getItem("userMsisdn"),
};

const msisdnSlice = createSlice({
  name: "msisdnSlice",
  initialState,
  reducers: {
    setMsisdn: (state, payload) => {
      state.msisdn = payload.payload;
    },
  },
});

export const { setMsisdn } = msisdnSlice.actions;

export default msisdnSlice.reducer;
